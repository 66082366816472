//test for iterating over child elements
var langArray = [];
$('.vodiapicker option').each(function(){
  var img = $(this).attr("data-thumbnail");
  var text = this.innerText;
  var value = $(this).val();
  var item = '<li><img src="'+ img +'" alt="" value="'+value+'"/><span>'+ text +'</span></li>';
  langArray.push(item);
})

$('#a').html(langArray);

//Set the button value to the first el of the array
$('.btn-select').html(langArray[0]);
$('.btn-select').attr('value', 'en');

//change button stuff on click
$('#a li').click(function(){
   var img = $(this).find('img').attr("src");
   var value = $(this).find('img').attr('value');
   var text = this.innerText;
   var item = '<li><img src="'+ img +'" alt="" /><span>'+ text +'</span></li>';
  $('.btn-select').html(item);
  $('.btn-select').attr('value', value);
  $(".b").toggle();
  //console.log(value);
});

$(".btn-select").click(function(){
        $(".b").toggle();
    });

//check local storage for the lang
var sessionLang = localStorage.getItem('lang');
if (sessionLang){
  //find an item with value of sessionLang
  var langIndex = langArray.indexOf(sessionLang);
  $('.btn-select').html(langArray[langIndex]);
  $('.btn-select').attr('value', sessionLang);
} else {
   var langIndex = langArray.indexOf('ch');
  // console.log(langIndex);
  $('.btn-select').html(langArray[langIndex]);
  //$('.btn-select').attr('value', 'en');
}

$(document).ready(function(){
    if ($(window).width() < 767){
        $('#icon1 span').text('VPCC');
        $('#icon2 span').text('CC Viên');
        $('#icon3 span').text('Lịch sử CC');
    }
});

// js verifi input
$(`[data-index=1]`).focus();

$('.verify-input-field').keypress(function(e){
  let inputBoxIndex = $(e.target).attr('data-index');
  let inputBox = $(e.target);
  
  if(inputBox.val().length > 0) {
    e.preventDefault();
  }
})


$('.verify-input-field').keyup(function(e){
  
  checkInput();
  
  let inputBoxIndex = $(e.target).attr('data-index');
  let pressedKeyCode = e.keyCode | e.which;
  let nextInputBox = $(`[data-index=${Number(inputBoxIndex) + 1}]`);
  let prevInputBox = $(`[data-index=${Number(inputBoxIndex) - 1}]`);
  
  if(pressedKeyCode !== 8 && pressedKeyCode !== 37 && pressedKeyCode !== 9 && pressedKeyCode !== 16  && nextInputBox.val().length === 0 || pressedKeyCode === 39) {
    nextInputBox.focus();
  } else if(pressedKeyCode === 8 || pressedKeyCode === 37) {
    prevInputBox.focus();
  }
    
})

function checkInput() {
  let finalInput = '';
  for(i=1; i<=6; i++) {
    let thisInput = $(`[data-index=${i}]`).val();
    finalInput = finalInput + thisInput.toString();
  }
  
  if (finalInput === '23f34') {
    $('.verify-input-field').addClass('verified');
  } else {
    $('.verify-input-field').removeClass('verified');
  }
}
// chat log
$(document).on('click', '.panel-heading span.icon_minim', function (e) {
  var $this = $(this);
  if (!$this.hasClass('panel-collapsed')) {
      $this.parents('.panel').find('.panel-body').slideUp();
      $this.addClass('panel-collapsed');
      $this.removeClass('glyphicon-minus').addClass('glyphicon-plus');
  } else {
      $this.parents('.panel').find('.panel-body').slideDown();
      $this.removeClass('panel-collapsed');
      $this.removeClass('glyphicon-plus').addClass('glyphicon-minus');
  }
});
$(document).on('focus', '.panel-footer input.chat_input', function (e) {
  var $this = $(this);
  if ($('#minim_chat_window').hasClass('panel-collapsed')) {
      $this.parents('.panel').find('.panel-body').slideDown();
      $('#minim_chat_window').removeClass('panel-collapsed');
      $('#minim_chat_window').removeClass('glyphicon-plus').addClass('glyphicon-minus');
  }
});
$(document).on('click', '#new_chat', function (e) {
  var size = $( ".chat-window:last-child" ).css("margin-left");
   size_total = parseInt(size) + 400;
  alert(size_total);
  var clone = $( "#chat_window_1" ).clone().appendTo( ".container" );
  clone.css("margin-left", size_total);
});
$(document).on('click', '.icon_close', function (e) {
  //$(this).parent().parent().parent().parent().remove();
  $( "#chat_window_1" ).remove();
});